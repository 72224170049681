<template>
  <div class="fill-height interactive-block-wrap" :class="{ 'large-screens': $vuetify.breakpoint.mdAndUp }">
    <Stepper :systemData="systemData" :fieldOfCareData="fieldOfCareData" />
    <Navigation />
  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue'
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'InteractiveBlock',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    Stepper: Stepper,
    Navigation: Navigation
  }
}
</script>

<style scoped lang="scss">
.interactive-block-wrap {
  position: relative;
  //overflow: hidden;

  &.large-screens {
    max-width: 800px;
  }
}
</style>
