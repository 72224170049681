<template>
  <div class="fill-height start-div white--text" :style="bgStyles">
    <v-container fill-height class="pa-0">
      <div class="text-div d-md-flex flex-column justify-center fill-height pa-5 pt-10 mx-0 pa-lg-10" :class="{ 'flex-fill': $vuetify.breakpoint.smAndDown }">
        <div class="my-auto">
          <div class="pb-12">
            <img :src="require('@/assets/logo.svg')" width="200" />
            <h1 class="font-weight-regular mb-8" style="font-size: 1.7rem">CONFIGURATOR</h1>
            <h2 class="font-weight-light headline">Build the perfect <span class="font-weight-bold">MESI mTABLET</span> system for your practice.</h2>
          </div>
          <div class="mt-10">
            <PreSelect :systemData="systemData" :fieldOfCareData="fieldOfCareData" />
          </div>
          <div class="d-flex">
            <v-btn text class="text-right ml-auto" color="white" @click="skip"><span class="">Create custom</span><v-icon right>mdi-chevron-right</v-icon></v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import fieldOfCareData from '@/data/fields.json'
import PreSelect from '@/components/PreSelect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  components: {
    PreSelect
  },
  data: function () {
    return {
      bgStyles: {
        backgroundImage: 'url(' + require('@/assets/jumbo.jpg') + ')'
      }
    }
  },
  mounted () {
    this.$localStorage.set('mConf_startPageShown', true)
  },
  computed: {
    ...mapGetters([
      'selectedField'
    ]),
    fieldOfCareData () {
      return fieldOfCareData
    },
    systemDataOk () {
      return typeof this.systemData !== 'undefined'
    },
    systemData () {
      if (typeof this.selectedField === 'undefined') {
        return {}
      }
      return this.getObjectWithIDFromArr(fieldOfCareData, this.selectedField)
    }
  },
  methods: {
    skip () {
      this.trackEvent('SkipFieldSelectOnStart', 'ButtonClick')
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
.start-div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .text-div {
    background: rgba(0, 0, 0, 0.24);
    max-width: 550px;
  }
}

.c-divider {
  background: white;
  height: 5px;
}
</style>
