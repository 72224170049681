<template>
  <div class="fill-height bg pt-5 text-center">
    <v-container class="narrow-container">
      <img :src="src" width="120" class="mx-auto" />
    </v-container>

    <v-container class="white narrow-container">
      <div class="pt-5 px-5">
        <div class="mb-10">
          <div class="display-1 mb-3">Welcome to the future of healthcare!</div>
          <div class="headline">
            Thank you for subscribing to our MESI mTABLET diagnostic solution. Our purpose is to equip you with everything you need to make the best clinical decision for your patients.
            One of our representatives will be in touch shortly with more informaton on our MESI Subscription Service agreement.</div>
        </div>
        <p>
            Get ready by watching our <a href="https://www.mesimedical.com/support/mesi-mtablet/mesi-mtablet-tutorials/" target="_blank">tutorial videos</a> on how to set up and use your first MESI mTABLET.
        </p>
        <p>
          You can also contact us via <a href="mailto:subscriptions@mesimedical.com">subscriptions@mesimedical.com</a>.
        </p>
        <!-- <div class="mt-7">
          <v-btn  text to="terms-conditions" color="primary lighten-2" class="mt-3">Terms & Conditions</v-btn>
        </div> -->
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Thank You'
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.narrow-container {
  max-width: 800px;
}
</style>
