<template>
  <div class="visual-block-step pa-6" :class="{ 'active-step' : isActiveStep }" ref="Layout">
    <div class="text-center" v-if="!allImagesLoaded">
      <v-img :src="imgPlaceholder" rel="preload" :lazy-src="imgPlaceholder" width="100%">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate color="accent"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-img
      class="layout-img"
      :class="{ 'active' : isSelectedLayout(img.id), 'loaded': allImagesLoaded }"
      :alt="img.id"
      v-for="(img,index) in layoutOptions"
      :src="img.src"
      @load="imgLoaded"
      width="100%"
      :v-if="allImagesLoaded && isSelectedLayout(img.id)"
      :key="index">
    </v-img>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import layoutOptions from '@/data/layouts.json'

export default {
  name: 'ExtModulesVisual',
  props: {
    stepIndex: Number,
    systemData: Object,
    fieldOfCareData: Array,
    isActiveStep: Boolean
  },
  data: function () {
    return {
      atLeastOneImageLoaded: false,
      numberOfImagesLoaded: 0
    }
  },
  computed: {
    ...mapGetters([
      'activeStep',
      'steps',
      'selectedLayout'
    ]),
    imgPlaceholder () {
      return require('@/assets/layout/visual/table.png')
    },
    stepOptions () {
      return this.steps[this.stepIndex].options
    },
    layoutOptions () {
      const l = layoutOptions.map((obj) => ({ id: obj.id, src: require('@/assets/layout/visual/' + obj.img) }))
      return l
    },
    allImagesLoaded () {
      return this.numberOfImagesLoaded === this.layoutOptions.length
    },
    componentName () {
      return this.steps[this.stepIndex].componentName
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  },
  methods: {
    imgLoaded () {
      this.numberOfImagesLoaded++
      if (!this.atLeastOneImageLoaded) {
        this.atLeastOneImageLoaded = true
      }
    },
    isSelectedLayout (layoutID = '') {
      return layoutID === this.selectedLayout
    }
  }
}
</script>

<style scoped lang="scss">
.layout-img {
  &.loaded {
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>
