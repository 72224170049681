<template>
  <v-col
    cols="12"
    class="d-flex flex-column justify-center"
  >
    <v-card
      color="white"
      class="mb-5 mb-lg-7 rounded d-flex flex-column conf-card"
      width="100%"
    >
      <v-card-title
        class="px-5 pt-3"
        :class="selectedPackageData.colorClassBox"
        cy-data-selected-package-title
      >
        <v-row>
          <v-col cols="10" lg="9" class="my-auto">
            <div class="text-h4 text-lg-h3">MESI <span class="font-weight-bold" :class="selectedPackageData.colorClass">{{ selectedPackageData.name }}</span> PLAN</div>
            <div class="text-md-h6 text-xl-h5 font-weight-light">
              {{ selectedPackageData.title }}
            </div>
          </v-col>
          <v-col cols="2" lg="3" class="text-right flex-wrap" :class="showDiscount ? '' : 'my-5'">
            <div v-if="showDiscount" class="font-weight-light text-h6 text-lg-h4 text-decoration-line-through grey--text">{{ numberFormatter.format(priceFinalGreenDiscountedFrom) }}</div>
            <div class="font-weight-bold text-h5 text-lg-h3">{{ packagePrice }}</div>
            <div class="font-weight-light caption text-lg-h6"> / month</div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card
      color="white"
      class="pb-3 rounded d-md-flex flex-column conf-card"
      width="100%"
    >
      <v-card-text class="px-5 py-3">
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="text-h6 mb-3 font-weight-bold">Plan features</h3>
            <div v-html="selectedPackageData.features" />
          </v-col>
          <v-col cols="12" lg="6">
            <h3 class="text-h6 mb-3 font-weight-bold">Plan perks</h3>
            <div v-html="selectedPackageData.perks" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-h6 mb-3 font-weight-bold">Details</v-col>
          <v-col
            v-for="(detail, k) in selectedPackageData.details"
            :key="k"
            cols="6"
            lg="4"
            class="py-2"
          >
            <span class="font-weight-bold">{{ k }}:</span> {{ detail }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectedPackage',
  computed: {
    ...mapGetters([
      'priceFinal',
      'priceFinalGreen',
      'priceFinalGreenDiscountedFrom',
      'priceFinalPremium',
      'numberFormatter',
      'selectedPackageData'
    ]),
    packagePrice () {
      return this.numberFormatter.format(this.selectedPackageData.userPrice)
    },
    showDiscount () {
      if (this.selectedPackageData.name === 'GREEN') {
        return true
      }
      return false
    },
    packageSelected () {
      if (this.selectedPackageData.name === 'LITE') {
        return this.litePackage
      } else if (this.selectedPackageData.name === 'GREEN') {
        return this.greenPackage
      } else if (this.selectedPackageData.name === 'PREMIUM') {
        return this.premiumPackage
      } else {
        return ''
      }
    }
  }
}

</script>

<style lang="scss">
/* .details-item {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  color: var(--v-accent-base);
} */
</style>
