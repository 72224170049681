<template>
  <v-snackbar
    class="m-snackbar"
    vertical
    bottom
    right
    light rounded
    :timeout="8000"
    :max-width="300"
    v-model="visible"
    @input="close"
  >
    {{ generalSnackbarText }}
    <v-btn depressed @click="close">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralSnackbar',
  data: function () {
    return {
      visible: false
    }
  },
  watch: {
    generalSnackbarVisible (val) {
      this.visible = val
    }
  },
  computed: {
    ...mapGetters([
      'generalSnackbarVisible',
      'generalSnackbarText'
    ])
  },
  methods: {
    close () {
      this.$store.commit('HIDE_GENERAL_SNACKBAR')
    }
  }
}
</script>

<style lang="scss">
/* All of these should work via props but they do not! */
.m-snackbar {
  left: auto !important;

  .v-snack__wrapper {
    background: white !important;
    color: var(--v-primary-base) !important;
    max-width: 550px;
    border-radius: 1rem !important;
  }
}
</style>
