<template>
  <div class="section-title mb-8">
    <div class="font-weight-regular title d-flex align-center" :class="{ 'text-uppercase' : !noUppercase }">
      <slot></slot>
    </div>
    <div class="font-weight-light subtitle">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    noUppercase: {
      type: Boolean,
      default: false
    }
  }
}
</script>
