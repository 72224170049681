<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="900"
    content-class="elevation-0"
  >
    <v-btn class="mb-3" block text depressed color="white" @click="closeDialog">
      <span class="text-decoration-underline">Close</span>
    </v-btn>
    <v-card>
      <v-card-title>Frequently Asked Questions</v-card-title>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(faq,i) in faqs"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ faq.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ faq.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <v-btn class="mt-3" block text depressed color="white" @click="closeDialog">
      <span class="text-decoration-underline">Close</span>
    </v-btn>
  </v-dialog>
</template>

<script>
import faqs from '@/data/faqs.json'

export default {
  name: 'FAQ',
  data: function () {
    return {
      dialogVisible: true,
      faqs: faqs
    }
  },
  watch: {
    dialogVisible (visible) {
      if (visible) {
        this.$store.commit('SHOW_FAQ')
      } else {
        this.closeDialog()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$store.commit('SHOW_FAQ', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
