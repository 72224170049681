import { getDefaultState } from '@/store/state'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import getters from '@/store/getters'

export default {
  state: getDefaultState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {}
}
