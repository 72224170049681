<template>
  <v-app :class="largeScreensClass">
    <div ref="mainAppDiv" class="fill-height" :class="largeScreensClass">
      <router-view/>
    </div>
    <TermsConditionsDialog v-if="isTermsDialogVisible" />
    <FAQDialog v-if="isFAQDialogVisible" />
    <vue-cookie-accept-decline
      :element-id="'cookieNotice'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      @status="cookieStatus"
      @clickedAccept="cookieClickedAccept"
      @clickedDecline="cookieClickedDecline"
    >
      <div
        slot="message"
        class="cookie-message"
      >
        This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our <a
          class="privacy-link"
          href="https://www.mesimedical.com/privacy-policy/"
          target="_blank"
        >Privacy Policy</a>.
      </div>

      <div slot="declineContent">
        Decline
      </div>

      <div slot="acceptContent" cy-data-close-cookie-notice>
        Accept
      </div>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<style lang="scss" scoped>
#app {
  &.large-screens {
    height: 100vh;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import TermsConditionsDialog from '@/components/dialogs/TermsConditions.vue'
import FAQDialog from '@/components/dialogs/FAQ.vue'

export default {
  name: 'MainApp',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'MESI mTABLET Configurator',
    // all titles will be injected into this template
    titleTemplate: '%s | Configure your personal MESI mTABLET System',
    meta: [
      { name: 'twitter:card', content: 'MESI mTABLET Configurator' },
      { name: 'twitter:title', content: 'MESI mTABLET Configurator' },
      { name: 'twitter:description', content: 'Configure your personal MESI mTABLET System' },
      { property: 'og:title', content: 'MESI mTABLET Configurator' },
      { property: 'og:site_name', content: 'MESI mTABLET Configurator' },
      { property: 'og:type', content: 'website' },
      { property: 'og:description', content: 'Configure your personal MESI mTABLET System' }
    ]
  },
  components: {
    TermsConditionsDialog,
    FAQDialog
  },
  data () {
    return {
      statusCookie: null
    }
  },
  mounted () {
    window.addEventListener('resize', this.onWindowResize)
    this.$nextTick(() => {
      this.onWindowResize()
      this.updateStepHeight()
    })
  },
  created () {
    if (!this.$localStorage.get('mConf_startPageShown', false) && this.$router.currentRoute.name !== 'Code') {
      this.$router.push('start')
    }

    this.onWindowResize = this.debounce(this.onWindowResize)
    this.updateNegativeMargin = this.debounce(this.updateNegativeMargin)
    this.updateVisualLine = this.debounce(this.updateVisualLine)
    this.updateStepHeight = this.debounce(this.updateStepHeight)
    this.$store.dispatch('INIT_DATA')
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResize)
  },
  computed: {
    ...mapGetters([
      'steps',
      'activeStep',
      'isLastStep',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'visualLineHeight',
      'visualBlockNegativeMargin',
      'isTermsDialogVisible',
      'isFAQDialogVisible'
    ]),
    largeScreensClass () {
      return { 'large-screens': this.isDesktop() }
    },
    stepHeights () {
      return this.steps.map((obj) => typeof obj.visualStepHeight !== 'undefined' ? obj.visualStepHeight : 0)
    }
  },
  watch: {
    $route (to, from) {
      this.onWindowResize()
    },
    activeStep (step) {
      this.updateStepHeight()
      this.trackScreenView('Step' + step)

      if (this.isLastStep) {
        this.$store.commit('SET_VISUAL_LINE_HEIGHT', 0)
        if (this.selectedExtensionsModules.includes('TBISYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
          this.$store.dispatch('ADJUST_ABI_TBI')
        }
      } else {
        if (this.selectedExtensionsModules.includes('TBPSYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
          this.$store.dispatch('READJUST_ABI_TBI')
        }
        this.updateVisualLine()
      }
      this.updateNegativeMargin()
    },
    stepHeights () {
      // TODO: Fires twice! should only once
      // console.log('update visual line & negative margin')
      this.updateVisualLine()
      this.updateNegativeMargin()
    },
    selectedApplications () {
      this.updateStepHeight(true)
    },
    selectedAccessories () {
      this.updateStepHeight(true)
    }
  },
  methods: {
    onWindowResize () {
      // console.log('onWindowResize inside debounce')
      this.adjustAppHeightVuex()
      this.$store.commit('REFRESH_VISUAL_BLOCK_KEY')
      this.updateStepHeight()
    },
    adjustAppHeightVuex () {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.$store.commit('SET_APP_HEIGHT', 'auto')
        return
      }
      const h = this.$refs.mainAppDiv.clientHeight
      this.$store.commit('SET_APP_HEIGHT', h)
    },
    updateVisualLine () {
      this.$store.commit('SET_VISUAL_LINE_HEIGHT', this.stepHeights[this.activeStep])
    },
    updateStepHeight (lineAndMarginToo = false) {
      const componentName = this.steps[this.activeStep].componentName

      if (!this.$root.$refs[componentName]) {
        // console.log('$root.$refs undefined for ' + componentName)
        return
      }

      this.$store.commit('SET_STEP_VISUAL_HEIGHT', { stepIndx: this.activeStep, height: this.$root.$refs[componentName].ref.clientHeight })
    },
    updateNegativeMargin () {
      // re-set all visual step ref heights so we have latest information
      for (const componentName in this.$root.$refs) {
        const indx = this.$root.$refs[componentName].index
        const ref = this.$root.$refs[componentName].ref

        this.$store.commit('SET_STEP_VISUAL_HEIGHT', { stepIndx: indx, height: ref.clientHeight })
      }

      let hPreviousSteps = 0

      if (this.activeStep === 0 || this.isLastStep) {
        hPreviousSteps = 0
      } else {
        hPreviousSteps = this.stepHeights.reduce(this.sumStepHeights, 0)
      }

      if (this.isDesktop()) {
        this.$store.commit('SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP', hPreviousSteps)
      } else {
        this.$store.commit('SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP', 0)
      }
    },
    sumStepHeights (acc, currentVal, currIndx) {
      if (currIndx < this.activeStep && !isNaN(currentVal)) {
        return acc + currentVal
      }
      return acc
    },
    cookieStatus (status) {
      this.statusCookie = status
    },
    cookieClickedAccept () {
      this.statusCookie = 'accept'
    },
    cookieClickedDecline () {
      this.statusCookie = 'decline'
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-application,
.theme--light.v-card {
  color: var(--v-primary-base) !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.cookie-message {
  .privacy-link {
    color: #8CC63E;
  }
}
.cookie__floating__buttons__button {
  &.cookie__floating__buttons__button--accept {
    color: #8CC63E;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #8CC63E;
    }
  }
  &.cookie__floating__buttons__button--decline {
    color: #EA2227;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #EA2227;
    }
  }
}
</style>
