<template>
  <div class="pt-3 pb-12 mt-8 mb-12" :class="{ 'active-step' : isActiveStep}" ref="Plan">

    <v-item-group>
      <v-container class="pa-0">

        <v-row v-if="selectedPackage === null">
          <AllPackages
            v-for="(p, k) in packages"
            :key="k"
            :packageCurrent="p"
          />
        </v-row>

        <v-row v-else>
          <SelectedPackage cy-data-selected-package-component />
        </v-row>

      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import AllPackages from '@/components/visualSteps/partials/AllPackages.vue'
import SelectedPackage from '@/components/visualSteps/partials/SelectedPackage.vue'
import packages from '@/data/packages.json'
import { mapGetters } from 'vuex'

export default {
  name: 'PlanVisual',
  props: {
    stepIndex: Number,
    selected: Array,
    systemData: Object,
    isActiveStep: Boolean
  },
  components: {
    SelectedPackage, AllPackages
  },
  data: function () {
    return {
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => this.emailRegex.test(v) || 'E-mail must be valid'
      ],
      sendDisabled: false
    }
  },
  computed: {
    ...mapGetters([
      'configURL',
      'steps',
      'FORMURL_SUBSCRIPTION',
      'priceFinal',
      'priceFinalGreen',
      'priceFinalPremium',
      'selectedExtensionsModules',
      'selectedPackage'
    ]),
    packages () {
      return packages.map(arrObj => ({ ...arrObj, userPrice: this[arrObj.userPriceStateKey] }))
    },
    componentName () {
      return this.steps[this.stepIndex].componentName
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  }
}

</script>
