<template>
  <div>
    <sectionTitle>
      Choose a layout
      <template v-slot:subtitle>
        You can choose from multiple layouts, based on your healthcare practice and your preferred way of using MESI mTABLET.
        <!--Choosing a layout will automatically preselect the neccessary accessories shown in the next step.-->
        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on }">
            <v-icon color="grey lighten-1" v-on="on">mdi-information</v-icon>
          </template>
          <div>
            The layout image is  for illustrative purposes only. The final appearance depends on selected items in your configuration.
          </div>
        </v-tooltip>
      </template>
    </sectionTitle>
    <div class="pb-12">
      <v-row>
        <v-col
          v-for="(item, k) in layoutOptions"
          :key="k"
          cols="6"
          md="4"
          lg="3"
          class="d-flex child-flex px-5 mb-5"
          >
          <div
            class="item"
            :class="itemClass(item)"
          >
            <imgCircle
              @click="toggleItem(item)"
              :item="item"
              data-cy-layout-item
              :src="imgPath(item.img)"
              :imgClass="itemClass(item)"
              :isSelected="isSelected(item)"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sectionTitle from '@/components/sectionTitle.vue'
import imgCircle from '@/components/imgCircle.vue'
import layoutOptions from '@/data/layouts.json'

export default {
  name: 'Layout',
  components: {
    sectionTitle, imgCircle
  },
  computed: {
    ...mapGetters([
      'steps',
      'activeStep',
      'disabledLayouts',
      'selectedExtensionsModules',
      'layouts',
      'selectedLayout'
    ]),
    layoutOptions () {
      return layoutOptions
    }
  },
  methods: {
    toggleItem (item) {
      if (this.isDisabled(item)) {
        return
      }

      this.$store.commit('SET_LAYOUT', item.id)
      this.$store.commit('SYNC_DATA')
    },
    isSelected (item) {
      return this.selectedLayout === item.id
    },
    isDisabled (obj) {
      return obj.comingSoon || this.disabledLayouts.includes(obj.id)
    },
    itemClass (obj) {
      let classes = ''
      if (this.isDisabled(obj)) {
        classes += 'disabled'
      }
      return classes
    },
    imgPath: path => require('@/assets/layout/interactive/' + path)
  }
}
</script>
