<template>
  <div>
    <sectionTitle>
      Choose a monthly plan
      <template v-slot:subtitle>
        Our monthly plans are designed with different type of healthcare environment in mind and tailored to meet every need out there. From a small family doctor´s office to a large hospital.
        <!--Choosing a layout will automatically preselect the neccessary accessories shown in the next step.-->
        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on }">
            <v-icon color="grey lighten-1" v-on="on">mdi-information</v-icon>
          </template>
          <div>
            All plans are based on a 36-month contract with full warranty applied. Get more details by selecting a plan.
          </div>
        </v-tooltip>
      </template>
    </sectionTitle>

    <v-btn-toggle v-model="selectedPackage" borderless background-color="transparent" tile class="w-100 mb-5 m-subscribe-btns">
      <v-btn x-large active-class="m-active-0" cy-data-plan-checkbox-light :disabled="litePackageDisabled" elevation="1" value="Package 1 (small)" color="white" block class="mb-3 d-flex align-center justify-space-between rounded-lg px-4 py-10 py-md-10 px-md-8">
        <div class="text-left w-50">
          <div>LITE<span v-show="litePackageDisabled"> *</span></div>
          <div class="caption text-none white-space">The essentials</div>
        </div>
        <v-icon v-show="!litePackageDisabled" class="ml-2">
          mdi-gesture-double-tap
        </v-icon>
      </v-btn>
      <v-btn x-large active-class="m-active-2" cy-data-plan-checkbox-green value="Package 2 (medium)" elevation="1" color="white" block class="mb-3 d-flex align-center justify-space-between rounded-lg px-4 py-10 py-md-10 px-md-8">
        <div class="text-left w-50">
          <div>GREEN <span class="caption accent--text discount">now 15% off!</span></div>
          <div class="caption text-none white-space">Everything a modern practice needs</div>
        </div>
        <v-icon class="ml-2">
          mdi-gesture-double-tap
        </v-icon>
      </v-btn>
      <v-btn x-large active-class="m-active-3" cy-data-plan-checkbox-premium value="Package 3 (big)" elevation="1" color="white" block class="mb-3 d-flex align-center justify-space-between rounded-lg px-4 py-10 py-md-10 px-md-8">
        <div class="text-left w-50">
          <div>PREMIUM</div>
          <div class="caption text-none white-space">For our most demanding users</div>
        </div>
        <v-icon class="ml-2">
          mdi-gesture-double-tap
        </v-icon>
      </v-btn>
    </v-btn-toggle>

    <p v-show="litePackageDisabled" class="caption text-center">* LITE plan only available when 2 or less modules selected</p>
    <v-col cols="12" sm="6" class="mx-auto">
      <v-btn data-cy-send-inquiry-btn color="accent" :outlined="disabledCTA" block x-large depressed class="subtitle-1 flex-grow-0" :disabled="disabledCTA" @click="goToHSform">Proceed to order</v-btn>
    </v-col>
    <div class="caption text-center mt-5 px-lg-15">
      Please make sure you read our <span class="text-decoration-underline m-link" @click="openTermsConditions">Terms and Conditions</span> and <span class="m-link text-decoration-underline" @click="openFAQ">Frequently asked questions</span> before you proceed.
    </div>
    <!--ShareDialog v-if="isShareDialogVisible" /-->
  </div>
</template>

<script>
// import ShareDialog from '@/components/dialogs/Share.vue'
import sectionTitle from '@/components/sectionTitle.vue'
import packages from '@/data/packages.json'
import { mapGetters } from 'vuex'

export default {
  name: 'Plan',
  props: {
    fieldOfCareData: Array
  },
  components: {
    // ShareDialog,
    sectionTitle
  },
  computed: {
    ...mapGetters([
      'isShareDialogVisible',
      'litePackageDisabled',
      'configURL',
      'FORMURL_SUBSCRIPTION',
      'priceFinal',
      'priceFinalGreen',
      'priceFinalGreenDiscountedFrom',
      'priceFinalPremium',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'numberFormatter'
    ]),
    selectedPackage: {
      get () {
        return this.$store.state.selectedPackage
      },
      set (value) {
        const modifiedPackages = packages.map(arrObj => ({ ...arrObj, userPrice: this[arrObj.userPriceStateKey] }))
        this.$store.commit('SELECT_PACKAGE', [value, modifiedPackages])
      }
    },
    selectedPackageNum () {
      if (!this.selectedPackage) {
        return null
      }

      if (this.selectedPackage.includes('3')) {
        return 3
      } else if (this.selectedPackage.includes('2')) {
        return 2
      } else if (this.selectedPackage.includes('1')) {
        return 1
      }
      return null
    },
    disabledCTA () {
      return this.selectedPackage === null
    }
  },
  methods: {
    editConfig () {
      this.trackEvent('Edit', 'ButtonClick')
      this.$store.commit('CHANGE_STEP', 0)
    },
    openShareDialog () {
      this.trackEvent('Share', 'ButtonClick')
      this.$store.commit('SHOW_SHARE_DIALOG')
    },
    goToHSform () {
      const extMods = this.selectedExtensionsModules.toString()
      const applications = this.selectedApplications.toString()
      const accessories = this.selectedAccessories.toString()
      this.trackEvent('SelectPackageDialog-ContinueToHSFormCTA', 'ButtonClick')
      window.open(this.FORMURL_SUBSCRIPTION + '?url=' + encodeURIComponent(this.configURL) + '&package=' + this.selectedPackage + '&extMods=' + extMods + '&apps=' + applications + '&accs=' + accessories, '_blank')
    },
    openTermsConditions () {
      this.trackEvent('Terms & Conditions', 'ButtonClick')
      this.$store.commit('SHOW_TERMS_CONDITIONS')
    },
    openFAQ () {
      this.trackEvent('FAQ', 'ButtonClick')
      this.$store.commit('SHOW_FAQ')
    }
  }
}
</script>

<style>
.m-link {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.white-space {
  white-space: initial;
}

.v-btn-toggle {
  flex-direction: column;
}

.caption-text {
  color: rgba(0,0,0,.38);
}

.m-subscribe-btns {
  .v-btn {
    opacity: 1 !important;
  }

  .v-btn--active {
    &.m-active-1 {
      background: lightgrey !important;
    }
    &.m-active-2 {
      color: white !important;
      background: var(--v-accent-base) !important;

      .discount {
        color: white !important;
      }
    }
    &.m-active-3 {
      color: white !important;
      background: var(--v-primary-base) !important;
    }
  }
}
</style>
