import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import ThankYou from '../views/ThankYou.vue'
import a404 from '../views/a404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/start',
    name: 'Start',
    component: Start
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/code/:code',
    name: 'Code',
    component: Home
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: ThankYou
  },
  {
    // will match everything
    path: '*',
    name: '404',
    component: a404
  }
]

const router = new VueRouter({
  routes
})

router.beforeRouteEnter = (to, from, next) => {
  next(vm => {
    vm.previousRoute = from
  })
}

export default router
