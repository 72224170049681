<template>
  <div>
    <ExtensionsModules :systemData="systemData" :fieldOfCareData="fieldOfCareData" />
  </div>
</template>

<script>
import ExtensionsModules from '@/components/interactiveSteps/partials/ExtensionsModules.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'StepExtModules',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    ExtensionsModules
  },
  computed: {
    ...mapGetters([
      'isSelectionDisabledDialogVisible'
    ])
  }
}
</script>
