
const activeStep = state => state.activeStep
const steps = state => state.steps
const isLastStep = state => state.activeStep === (state.steps.length - 1)

/**
 * Price that is not displayed, but is added to the sum of
 * all the prices defined for each package.
 */
const defaultPriceMTAB = state => state.defaultPriceMTAB
const fullPriceMTAB = state => state.fullPriceMTAB

const priceFull = state => state.priceFull
const price = state => state.price
const priceGreenPackage = state => state.priceGreenPackage
const pricePremiumPackage = state => state.pricePremiumPackage

const priceFinal = state => state.pricesAfterMtab.price
const priceFinalGreen = state => state.pricesAfterMtab.priceGreenPackage
const priceFinalGreenDiscountedFrom = state => state.pricesAfterMtab.greenDiscountFrom
const priceFinalPremium = state => state.pricesAfterMtab.pricePremiumPackage
const numberFormatter = state => state.numberFormatter
const selectedPackage = state => state.selectedPackage
const selectedPackageData = state => state.selectedPackageData
const litePackageDisabled = state => state.litePackageDisabled

const selectedField = state => state.selectedField

const selectedExtensionsModules = state => state.selected.extensionsModules
const disabledExtModules = state => state.disabled.extensionsModules

const preselectedApplications = state => state.preselected.applications
const preselectedAccessories = state => state.preselected.accessories

const selectedApplications = state => state.selected.applications
const selectedAccessories = state => state.selected.accessories

const selectedLayout = state => state.selected.layout
const disabledLayouts = state => state.disabled.layouts

const allowedApplications = state => state.allowed.applications
const allowedAccessories = state => state.allowed.accessories

const visualLineHeight = state => state.visualLineHeight

const appHeight = state => state.appHeight
const navigationHeight = state => state.navigationHeight

const isTermsDialogVisible = state => state.dialogs.termsConditions
const isFAQDialogVisible = state => state.dialogs.faq
const isThankYouDialogVisible = state => state.dialogs.thankYou
const isGenericDialogVisible = state => state.dialogs.generic
const isShareDialogVisible = state => state.dialogs.share
const isSelectionDisabledDialogVisible = state => state.dialogs.selectionDisabled
const disabledItemsBySelectingAnother = state => state.dialogs.disabledItemsBySelectingAnother

const selectionDisabledSnackbarVisible = state => state.snackbars.selectionDisabled
const selectionDisabledText = state => state.snackbars.selectionDisabledText
const generalSnackbarText = state => state.snackbars.generalSnackbarText
const generalSnackbarVisible = state => state.snackbars.general

const genericDialogContent = state => state.dialogs.genericDialogContent
const genericDialogLoading = state => state.dialogs.genericDialogLoading

const visualBlockKey = state => state.visualBlockKey
const visualBlockNegativeMargin = state => state.visualBlockNegativeMargin

const getStepVisualHeight = state => (stepIndx = 0) => {
  if (typeof state.steps[stepIndx] === 'undefined') {
    return
  }
  return state.steps[stepIndx].visualStepHeight
}

const configCode = state => state.configCode
const configURL = state => state.configURL
const configCodeSeparator = state => state.configCodeSeparator

const extModByID = (state) => (id) => {
  if (typeof state.extensionsModulesByID[id] === 'undefined') {
    return ''
  }
  return state.extensionsModulesByID[id]
}

const applicationByID = (state) => (id) => {
  if (typeof state.applicationsByID[id] === 'undefined') {
    return ''
  }
  return state.applicationsByID[id]
}

const layoutByID = (state) => (id) => {
  if (typeof state.layoutsByID[id] === 'undefined') {
    return ''
  }
  return state.layoutsByID[id]
}

const accessoryByID = (state) => (id) => {
  if (typeof state.accessoriesByID[id] === 'undefined') {
    return ''
  }
  return state.accessoriesByID[id]
}

const APIURL = state => state.APIURL
const FORMURL = state => state.FORMURL
const FORMURL_SUBSCRIPTION = state => state.FORMURL_SUBSCRIPTION

const mTabIncludedApps = state => state.mTabIncludedApps

const visualBlockVisibleMobile = state => state.visualBlockVisibleMobile

const extModIsSelected = (state) => {
  return state.selected.extensionsModules.length > 0
}

export default {
  activeStep,
  visualLineHeight,
  navigationHeight,
  appHeight,
  steps,
  isLastStep,
  defaultPriceMTAB,
  fullPriceMTAB,
  priceFull,
  price,
  priceGreenPackage,
  pricePremiumPackage,
  priceFinal,
  priceFinalGreen,
  priceFinalGreenDiscountedFrom,
  priceFinalPremium,
  numberFormatter,
  selectedPackage,
  selectedPackageData,
  litePackageDisabled,
  selectedField,
  selectedExtensionsModules,
  disabledExtModules,
  selectedApplications,
  allowedApplications,
  preselectedApplications,
  selectedLayout,
  disabledLayouts,
  selectedAccessories,
  preselectedAccessories,
  allowedAccessories,
  isTermsDialogVisible,
  isFAQDialogVisible,
  isThankYouDialogVisible,
  isGenericDialogVisible,
  isShareDialogVisible,
  isSelectionDisabledDialogVisible,
  selectionDisabledSnackbarVisible,
  selectionDisabledText,
  generalSnackbarText,
  generalSnackbarVisible,
  disabledItemsBySelectingAnother,
  genericDialogContent,
  genericDialogLoading,
  visualBlockKey,
  visualBlockNegativeMargin,
  getStepVisualHeight,
  configCode,
  configURL,
  configCodeSeparator,
  extModByID,
  applicationByID,
  layoutByID,
  accessoryByID,
  APIURL,
  FORMURL,
  FORMURL_SUBSCRIPTION,
  mTabIncludedApps,
  visualBlockVisibleMobile,
  extModIsSelected
}
