<template>
  <v-dialog
    v-model="showDialog"
    content-class="elevation-0"
    width="700"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container class="text-center">
      <div class="flex-grow-1 white rounded px-5 py-9 mb-5">
        <img :src="src" width="150" />
        <div class="my-5" :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-3': $vuetify.breakpoint.mdAndUp}"><strong>Thank you</strong> for subscribing.</div>
        <div class="headline">We'll contact you as soon as possible.</div>
        <p>
          In the meantime, you can check our <a href="https://www.mesimedical.com/mesi-mtablet-video-tutorials/" target="_blank">MESI mTABLET video tutorials</a> or contact us via <a href="mailto:info@mesimedical.com">info@mesimedical.com</a>.
        </p>
      </div>
      <v-btn color="accent" block x-large rounded depressed class="subtitle-1" @click="closeDialogs">OK, Close</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  computed: {
    showDialog () {
      return this.show
    }
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  methods: {
    closeDialogs () {
      this.$store.commit('CLOSE_ALL_DIALOGS')
    }
  }
}
</script>
