<template>
  <div class="price-wrap text-left" cy-data-info-block>
    <div v-if="isLastStep" class="title text-center">Configuration overview</div>
    <div v-else class="price-div display-2">
      <div class="subtitle-1"><small>Starting from:</small></div>
      <span class="font-weight-bold">{{ numberFormatter.format(priceShown) }}</span>
      <span class="display-1 font-weight-light"> / month</span>
      <!--v-tooltip top max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn fab depressed icon v-on="on" class="d-inline-block ml-3" x-small>
            <v-icon color="grey lighten-1" large>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Your chosen confuiguration is also available in our MESI Subscription service, where you can choose your ideal monthly plan. If you would like to receive a an offer from our representative, choose the "Send inquiry" option.</span>
      </v-tooltip-->
    </div>
  </div>
</template>

<script>
import allExtModData from '@/data/extensionsModules.json'
import { mapGetters } from 'vuex'

export default {
  name: 'PriceDisplay',
  computed: {
    ...mapGetters([
      'isLastStep',
      'price',
      'priceGreenPackage',
      'pricePremiumPackage',
      'selectedExtensionsModules',
      'selectedApplications',
      'defaultPriceMTAB',
      'priceFull',
      'priceFinal',
      'priceFinalGreen',
      'numberFormatter'
    ]),
    visualPriceWithMTABLET () {
      const mtabletIncluded = this.isMtabletIncludedInSelection(this.selectedExtensionsModules.slice(), allExtModData)
      if (!mtabletIncluded && this.selectedExtensionsModules.length > 0) {
        // console.warn('mTABLET not included (WE ADD 19 - ONLY VISUALLY)')
        return this.price + this.defaultPriceMTAB
      }
      return this.price
    },
    priceShown () {
      return this.selectedExtensionsModules.length < 3 ? this.priceFinal : this.priceFinalGreen
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
