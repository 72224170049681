<template>
  <v-col
    cols="12"
    sm="4"
    class="d-flex justify-center"
  >
    <v-item :value="packageCurrent.HubspotValue">
      <v-card
        color="white"
        class="pb-3 rounded d-flex flex-column conf-card"
        :class="packageCurrent.name === 'LITE' && litePackageDisabled ? 'disable-card' : ''"
        width="100%"
        :style="cardStyle(packageCurrent)"
      >
        <v-card-title class="justify-center headline font-weight-bold" :class="packageCurrent.colorClass">
          {{ packageCurrent.name }}
          <!-- <v-tooltip top max-width="350" v-if="p.info">
            <template v-slot:activator="{ on }">
              <v-icon color="grey lighten-1" right v-on="on">mdi-information</v-icon>
            </template>
            <span>{{ p.info }}</span>
          </v-tooltip> -->
        </v-card-title>
        <div class="bg pa-3 mb-3 text-center">
          <div class="font-weight-light text-uppercase">your configuration</div>
          <span
            class="font-weight-bold display-1"
            :data-cy="'price-'+packageCurrent.name"
          >
            {{ numberFormatter.format(packageCurrent.userPrice) }}
          </span>
          <span class="font-weight-light"> / month</span>
        </div>
        <div class="caption">
          <ul class="custom-list px-5 ml-0 text-center">
            <li v-for="(l, lk) in packageCurrent.list" :key="lk" class="list-item py-3" v-html="l"></li>
          </ul>
        </div>
        <!--v-card-actions class="px-4 flex-grow-1 d-flex justify-end flex-column">
          <v-btn class="flex-grow-0" rounded block depressed color="accent" :outlined="!isActive(p.HubspotValue)">{{ isActive(p.HubspotValue) ? 'SELECTED' : 'SELECT' }}</v-btn>
        </v-card-actions-->
      </v-card>
    </v-item>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AllPackages',
  props: {
    packageCurrent: Object
  },
  computed: {
    ...mapGetters([
      'configURL',
      'steps',
      'litePackageDisabled',
      'FORMURL_SUBSCRIPTION',
      'priceFinal',
      'priceFinalGreen',
      'priceFinalPremium',
      'selectedExtensionsModules',
      'numberFormatter',
      'selectedPackage'
    ])
  },
  methods: {
    cardStyle (p) {
      if (this.isActive(p.HubspotValue)) {
        return
      }
      return {
        opacity: 1
      }
    },
    isActive (name) {
      return this.selectedPackage === name
    }
  }
}

</script>

<style lang="scss">
.dialog-inner-content {
  box-shadow: none !important;
  overflow-x: hidden;
}

.rounded {
  border-radius: 15px !important;
}

.conf-card {
  transition: 0.3s opacity !important;

  &.disable-card {
    opacity: 0.4 !important;
  }
}

.custom-list {
  list-style-type: none;

  li {
    &:not(:first-child) {
      border-top: 2px solid var(--v-primary-lighten5);
    }
  }
}
</style>
