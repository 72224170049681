<template>
  <div class="visual-block-step step-2-visual-block bg pa-6 my-4" :class="{ 'active-step' : isActiveStep }" ref="Apps">
    <div class="pl-1 mb-2 title text-uppercase">Included</div>
    <div class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
        <div
          v-for="(selectedKey, k) in mTabIncludedApps"
          :key="selectedKey + k"
          class="d-inline-block ma-3 app-item"
          transition="slide-y-transition">
          <v-img :src="imgPath(selectedKey)" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selectedKey" :title="selectedKey" aspect-ratio="1.0" contain width="100">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <div
          v-for="(selectedKey, k) in preselectedApplications"
          :key="selectedKey + k"
          class="d-inline-block ma-3 app-item"
          transition="slide-y-transition">
          <v-img :src="imgPath(selectedKey)" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selectedKey" :title="selectedKey" aspect-ratio="1.0" contain width="100">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </transition-group>
    </div>

    <div class="pl-1 mb-2 mt-4 title text-uppercase" v-if="additionalApps.length > 0">Additional</div>
    <div class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
          <div
            v-for="(selectedKey, k) in additionalApps"
            :key="selectedKey + k"
            class="d-inline-block ma-3 app-item"
            transition="slide-y-transition">
            <v-img :src="imgPath(selectedKey)" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selectedKey" :title="selectedKey" aspect-ratio="1.0" contain width="100">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppsVisual',
  props: {
    stepIndex: Number,
    systemData: Object,
    fieldOfCareData: Array,
    isActiveStep: Boolean
  },
  computed: {
    lazyImgSrc () {
      return require('@/assets/placeholder.jpg')
    },
    ...mapGetters({
      selected: 'selectedApplications'
    }),
    ...mapGetters([
      'activeStep',
      'steps',
      'preselectedApplications',
      'mTabIncludedApps'
    ]),
    additionalApps () {
      return this.selected.filter((el) => {
        return !this.preselectedApplications.includes(el)
      })
    },
    componentName () {
      return this.steps[this.stepIndex].componentName
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  },
  methods: {
    imgPath (id) {
      const obj = this.getDataObj(id)
      if (typeof obj === 'undefined' || !obj.imgVisual) {
        // console.warn('MISSING APP PHOTO for ' + id, ' : ', obj)
        return ''
      }
      return require('@/assets/apps/visual/' + obj.imgVisual)
    },
    getDataObj (id = '') {
      return this.$store.getters.applicationByID(id)
    }
  }
}
</script>

<style lang="scss" scoped>
$transitionSpeed: 1s;

.visual-block-step {
  border-radius: 3rem;
}

img {
  max-width: 100%;
}

.tr-group-wrap {
  position: relative;
  overflow: hidden;

  .img {
    max-width: 100px;
  }
}

/**
 * https://vuejs.org/v2/guide/transitions.html
 */
.app-item {
  transition: all 1s;
}
.apps-wrap-enter, .apps-wrap-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.apps-wrap-leave-active {
  position: absolute;
}
</style>
