import HashMap from '@/data/codeHashMap.json'
import LayoutOptions from '@/data/layouts.json'
import store from './store'

const arrayRemoveDuplicates = function (arr = []) {
  return Array.from(new Set([...arr]))
}

const concatAndRemoveDuplicates = function (arr1 = [], arr2 = []) {
  return arrayRemoveDuplicates(arr1.concat(arr2))
}

const removeItemsFromArray = function (arr = [], removeItems = []) {
  return arr.filter((id) => {
    return !removeItems.includes(id)
  })
}

const getObjectWithIDFromArr = function (ar = [], id) {
  return ar.filter(obj => obj.id === id).pop()
}

const arrayIntersection = (arrayA, arrayB) => {
  const s = new Set(arrayB)
  return arrayA.filter(x => s.has(x))
}

const isMtabletIncludedInSelection = (extModsData, allExtModData) => {
  for (const id of extModsData) {
    const obj = allExtModData.slice().filter(obj => obj.id === id).pop()
    if (typeof obj.mtabletIncludedInPrice !== 'undefined' && obj.mtabletIncludedInPrice) {
      return true
    }
  }
  return false
}

const encodeData = (data) => {
  let code = ''
  const separator = store.getters.configCodeSeparator

  for (const id of data.selected.extensionsModules) {
    code += store.getters.extModByID(id).hashMapId
  }

  code += separator
  for (const id of data.selected.applications) {
    code += store.getters.applicationByID(id).hashMapId
  }

  code += separator
  const layoutID = store.getters.selectedLayout
  const layoutHashMapID = LayoutOptions.reduce((previous, current) => {
    if (current.id === layoutID) {
      return current.hashMapId
    }
    return previous
  }, '')
  code += layoutHashMapID

  code += separator
  for (const id of data.selected.accessories) {
    code += store.getters.accessoryByID(id).hashMapId
  }

  return code
}

const decodeCode = (code) => {
  // console.log('decode: ', code, HashMap)
  let selected = []
  let selectedExtModsArray = []
  let selectedApplicationsArray = []
  let selectedLayoutsArray = []
  let selectedAccessoriesArray = []

  if (code.includes('|')) {
    selected = code.split('|')
  } else {
    selected = code.split(store.getters.configCodeSeparator)
  }

  selectedExtModsArray = selected[0].match(/[\s\S]{1,2}/g) || []

  if (selected.length >= 2) {
    selectedApplicationsArray = selected[1].match(/[\s\S]{1,2}/g)
  }
  if (selected.length >= 3) {
    selectedLayoutsArray = selected[2].match(/[\s\S]{1,2}/g)
  }
  if (selected.length >= 4) {
    selectedAccessoriesArray = selected[3].match(/[\s\S]{1,2}/g)
  }

  selectedExtModsArray = selectedExtModsArray.map(hash => HashMap[hash])
  selectedLayoutsArray = selectedLayoutsArray.map(hash => HashMap[hash])
  selectedApplicationsArray = selectedApplicationsArray.map(hash => HashMap[hash])
  selectedAccessoriesArray = selectedAccessoriesArray.map(hash => HashMap[hash])

  return {
    selected: {
      extensionsModules: selectedExtModsArray,
      layouts: selectedLayoutsArray,
      applications: selectedApplicationsArray,
      accessories: selectedAccessoriesArray
    }
  }
}

const oldPriceByDiscount = (realPrice, discountPercent = 15) => {
  return Math.round(realPrice / ((100 - discountPercent) / 100))
}

export default {
  arrayRemoveDuplicates,
  concatAndRemoveDuplicates,
  removeItemsFromArray,
  getObjectWithIDFromArr,
  arrayIntersection,
  isMtabletIncludedInSelection,
  encodeData,
  decodeCode,
  oldPriceByDiscount
}
