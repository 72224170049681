<template>
  <div class="fill-height bg pt-5 text-center">
    <v-container class="narrow-container">
      <img :src="src" width="120" class="mx-auto" />
    </v-container>

    <v-container class="white narrow-container pb-10">
      <div class="pt-5 px-5">
        <div class="mb-10">
          <div class="display-1 mb-3">Whoops ...</div>
          <div class="headline">This page does not exists.</div>
        </div>
        <div>
          <v-btn rounded depressed large color="accent" to="start">New configuration</v-btn>
        </div>
        <div class="mt-7">
          <div class="mb-3 text-decoration-underline m-link" @click="openTermsConditions">Terms and Conditions</div>
          <div class="m-link text-decoration-underline" @click="openFAQ">FAQ</div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'a404',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '404 Not Found'
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  methods: {
    openTermsConditions () {
      this.trackEvent('Terms & Conditions', 'ButtonClick')
      this.$store.commit('SHOW_TERMS_CONDITIONS')
    },
    openFAQ () {
      this.trackEvent('FAQ', 'ButtonClick')
      this.$store.commit('SHOW_FAQ')
    }
  }
}
</script>

<style lang="scss" scoped>
.narrow-container {
  max-width: 800px;
}
</style>
