<template>
  <div class="systems-wrap mb-10 text-center">
    <sectionTitle>
      <span class="cy-fow">Choose your field of work</span>
      <v-tooltip right max-width="400">
        <template v-slot:activator="{ on }">
          <v-icon color="white" v-on="on" class="ml-3" right top>mdi-information</v-icon>
        </template>
        <small>
          Choosing one of the fields will automatically preselect some of the products. You can always adjust the preselected configuration. Skipping this step will allow you to configure your MESI mTABLET from the start.
        </small>
      </v-tooltip>
    </sectionTitle>
    <div>
      <v-btn depressed rounded block large
        :outlined="!isSelected(item.id)"
        v-for="(item,k) in fieldOfCareData"
        :key="k"
        :color="btnColorString(item.id)"
        @click="updateSelectedField(item.id)"
        class="c-mesi-btn"
        data-cy-preselect-btn
        :data-testid="item.id"
        :loading="loadingItem(item.id)"
        :class="{ selected: isSelected(item.id) }">
        {{ item.name }}
        <template v-slot:loader>
          selecting
          <v-progress-circular
            indeterminate
            right
            class="ml-3"
            size="20"
            width="2"
            color="white"
          ></v-progress-circular>
        </template>
      </v-btn>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'

export default {
  name: 'PreSelect',
  components: {
    sectionTitle
  },
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  data: function () {
    return {
      loadingSelectionId: null
    }
  },
  computed: {
    selectedFieldKey: {
      get: function () {
        return this.$store.getters.selectedField
      },
      set: function (newVal) {
      }
    }
  },
  methods: {
    updateSelectedField (id = '') {
      this.trackEvent('SelectFieldOnStart', 'ButtonClick')
      this.$store.dispatch('CHANGE_FIELD', id)
      this.loadingSelectionId = id
      const st = setTimeout(() => {
        this.loadingSelectionId = null
        this.$router.push({ path: '/' })
        clearTimeout(st)
      }, 1000)
    },
    isSelected (id) {
      return this.selectedFieldKey === id
    },
    btnColorString (id = '') {
      return 'accent'
    },
    loadingItem (id) {
      return this.loadingSelectionId === id
    }
  }
}
</script>
