<template>
  <v-dialog
    v-model="dialogVisible"
    content-class="elevation-0"
    width="1000"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <v-container class="text-center" data-cy-start-notice-dialog>
      <div class="flex-grow-1 white rounded pa-5 mb-5">
        <div class="display-1 accent--text mb-3">
          WELCOME TO THE FUTURE OF HEALTHCARE!
        </div>
        <div>
          Before you begin, we would like to give you a quick sneak peek into a brand new world of <strong>simplified diagnostics</strong>.
          <br />
          A world where <strong>one smart decision</strong> makes <strong>ALL the difference</strong>. For you and your patients.
        </div>
      </div>

      <div class="flex-grow-1">
        <v-row>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/mtablet.png')" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                MESI mTABLET
              </v-card-title>
              <v-card-text>
                This unique medical tablet sits at the core, giving life to the whole system. <strong>One device</strong>, unifying your experience, <strong>revolutionizing healthcare</strong>.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/modules-apps.png')" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                MODULES AND APPS
              </v-card-title>
              <v-card-text>
                The way you detect crucial information affects your perception of the world. Choose our wireless modules for an enhanced experience. Add Smart apps to perform <strong>advanced diagnostics</strong> with a <storng>simple click</storng>.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2 pa-md-5 rounded fill-height">
              <img :src="require('@/assets/dialogs/mrecords.png')" class="pt-3 px-3">
              <v-card-title class="justify-center accent--text">
                MESI mRECORDS
              </v-card-title>
              <v-card-text>
                The ability to collect, organize, store and access information is a vital function. That´s why we developed MESI mRECORDS, giving you <strong>full control over your data</strong>. Anytime, Anywhere.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-btn color="accent" block x-large rounded depressed class="subtitle-1 mt-5" @click="closeDialog" data-cy-start-cta>GET STARTED</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialogVisible: true
    }
  },
  created () {
    if (this.$router.currentRoute.name === 'Code') {
      this.dialogVisible = false
    } else {
      this.dialogVisible = true
    }
  },
  watch: {
    // when clicked outside of dialog, we close it as well
    // (need localStorage written now too)
    dialogVisible (val) {
      if (!val && this.$router.currentRoute.name !== 'Code') {
        this.closeDialog()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$localStorage.set('mConf_startNoticePopupClosed', true)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: 100px;
}
</style>
